import React from "react";
import Layout from "../components/Layout";
import Emoji from "../components/Emoji";
import "./services.css";

const SERVICES = [
  {
    title: "Full-Stack Developer",
    symbol: "stack",
    body:
      "From front to back, I've worked with codebases of all shapes and sizes - the good, the bad, and the ugly. Whether you're an early-stage startup building an MVP, or an established player looking to upgrade a legacy codebase, I can help you progress to the next level. The Golden Rule: always leave the codebase tidier than when you found it.",
    buzzwords: [
      "Python",
      "Django",
      "pytest",
      "JavaScript",
      "React",
      "Node.JS",
      "Jest",
      "Cypress",
      "SQL",
      "PostgreSQL",
      "Ruby on Rails",
      "Haskell",
    ],
  },
  {
    title: "DevOps Engineer",
    symbol: "ship",
    body:
      "Automation is essential to stay ahead in the modern world, but that shouldn't mean compromising on quality or security. My automated deployment pipelines have been used to coordinated releases for FCA-regulated clients, often several times a day. Combining battle-tested open-source software with industry best practices enables the whole team to iterate quickly and safely, with clear visibility.",
    buzzwords: [
      "Jenkins",
      "CI",
      "CD",
      "GitHub",
      "Heroku",
      "AWS",
      "Docker",
      "DigitalOcean",
      "Terraform",
      "Ansible",
      "Chef",
      "Vagrant",
    ],
  },
  {
    title: "Project Manager",
    symbol: "juggling",
    body:
      "I've led high-velocity teams—both skills-based and cross-functional—to success time and time again. Whether you practise Scrum, Kanban or Waterfall, the underlying principles are the same. Clear and open communication is key. Fostering an honest, blame-free culture encourages issues to be flagged (and fixed!) early. Baking quality in from the start means reliable progress, and fewer surprises when deadlines get near.",
    buzzwords: [
      "Lean",
      "Agile",
      "Scrum",
      "Kanban",
      "Jira",
      "Gantt",
      "Leadership",
    ],
  },
  {
    title: "Data Analyst",
    symbol: "graph",
    body:
      "Data isn't the same as information. I can help you transform your mountain of raw data into digestible, actionable charts and visualisations. I've used data anlysis techniques to solve problems for stakeholders across the organisation. Leadership benefit from well-selected metrics to give a concise summary of business performance. A well-designed realtime monitoring dashboard is invaluable for support engineers. Trend analysis can inform key management decisions.",
    buzzwords: [
      "Python",
      "Pandas",
      "Jupyter",
      "SQL",
      "R",
      "matplotlib",
      "Bokeh",
      "ETL",
    ],
  },
];

const Service = ({ symbol, title, buzzwords, children }) => {
  return (
    <article>
      <h2>
        <Emoji symbol={symbol} /> {title}
      </h2>
      <p>{children}</p>
      {
        // <footer className="padding-m">
        //   <h3>Buzzwords</h3>
        //   <ul className="cluster">
        //     {
        //       buzzwords.map((word) => (
        //       <li key={word} className="hpadding-s bg-orange border-radius-s">
        //         {word}
        //       </li>
        //     ))}
        //   </ul>
        // </footer>
      }
    </article>
  );
};

const ServicesPage = () => {
  return (
    <Layout>
      <div className="stack-m">
        <h1>I wear many hats</h1>
        <div className="role-grid">
          {SERVICES.map(({ title, symbol, body, buzzwords }) => (
            <Service
              symbol={symbol}
              title={title}
              key={title}
              buzzwords={buzzwords}
            >
              {body}
            </Service>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ServicesPage;
